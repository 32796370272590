<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <b-container fluid style="padding-right: 12.5px; padding-left: 12.5px">
        <b-row class="my-1">
          <b-col sm="3">
            <b-row class="my-1">
              <label>菜单类型查询:</label>
            </b-row>
            <b-row class="my-1">
              <div id="tt" style="position: relative">
                <v-select
                    dense
                    clearable
                    outlined
                    :menu-props="vselect"
                    attach="#tt2"
                    hide-details
                    item-text="name"
                    item-value="id"
                    v-model="search.categoryId"
                    :items="resourceDialog.categoryList"
                    placeholder="选择菜单类型查询"
                    ></v-select>
                </div>
                <div id="tt2" style="position: relative"></div>
            </b-row>
          </b-col>

          <b-col sm="3">
            <b-row class="my-1">
              <label>菜单名查询:</label>
            </b-row>
            <b-row class="my-1">
              <v-text-field
                dense
                outlined
                clearable
                v-model="search.name"
                placeholder="请输入菜单名"
                ></v-text-field>
            </b-row>
          </b-col>

          <b-col sm="3">
            <b-row class="my-1">
              <label>菜单URL查询:</label>
            </b-row>
            <b-row class="my-1">
              <v-text-field
                dense
                outlined
                clearable
                v-model="search.url"
                placeholder="请输入菜单URL"
                ></v-text-field>
            </b-row>
          </b-col>

          <b-col sm="3">
            <b-row class="my-1">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-1">
              <b-button
                variant="primary"
                @click="resource2page()"
                style="margin-right: 10px"
                >搜 索</b-button
              >
              <b-button variant="success" @click.prevent="resource2initAdd()"
                >新增菜单</b-button
              >
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-vertical-center">
          <thead>
            <tr>
              <th class="pl-0 text-center" style="width: 50px">#</th>
              <th class="pl-0" style="min-width: 100px">名称</th>
              <th class="pl-0" style="min-width: 100px">URL</th>
              <th class="pl-0" style="min-width: 100px">菜单分类</th>
              <th class="pl-0" style="min-width: 150px">操作</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in page.resourceList">
              <tr v-bind:key="i">
                <!-- 序号 -->
                <td class="pl-0 text-center">
                  {{ i + 1 }}
                </td>

                <!-- 名称 -->
                <td class="pl-0">
                  <a
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.name }}
                  </a>
                </td>

                <!-- URL -->
                <td class="pl-0">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.url }}
                  </span>
                </td>

                <!-- 菜单分类 -->
                <td class="pl-0">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.catName }}
                  </span>
                </td>

                <!-- 操作 -->
                <td class="pl-0">
                  <!-- 编辑 -->
                  <a
                    class="btn btn-icon btn-light btn-sm mx-3"
                    title="修改"
                    @click.prevent="resource2initEdit(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        title="修改"
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                    </span>
                  </a>
                  <!-- 删除 -->
                  <a v-if="item.bulidType"
                    class="btn btn-icon btn-light btn-sm"
                    title="删除"
                    @click.prevent="resource2del(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        title="删除"
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <!-- 翻页按钮 -->
        <div class="mt-3">
          <b-pagination
            limit="7"
            first-number
            last-number
            size="lg"
            align="right"
            v-model="search.pageNum"
            @change="resource2page()"
            :per-page="page.pageSize"
            :total-rows="page.rows"
          ></b-pagination>
        </div>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->

    <!-- start:新增 -->
    <v-app >
      <v-row justify="center">
        <v-dialog
          v-model="resourceDialog.dialog"
          persistent
          scrollable
          max-width="600px"
        >
          <v-form
            ref="form"
            lazy-validation
          >
            <v-card>
              <!-- 表单头部 -->
              <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>{{ resourceDialog.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="resourceDialog.dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-container style="padding-top: 10px">
                  <v-row>
                    <!-- 菜单类型 -->
                    <v-col cols="12" >
                      <div class="d-flex justify-content-between mt-n5">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >菜单类型*</label
                        >
                      </div>
                      <div>
                        <v-select
                          outlined
                          name="categoryId"
                          item-text="name"
                          item-value="id"
                          :rules="[myRules.selected]"
                          v-model="currResource.categoryId"
                          :items="resourceDialog.categoryList"
                          dense
                        ></v-select>
                      </div>
                    </v-col>

                    <!-- 名称 -->
                    <v-col cols="12" >
                      <div class="d-flex justify-content-between mt-n5">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >名称*</label
                        >
                      </div>
                      <div>
                        <v-text-field
                          type="input"
                          name="name"
                          :rules="[myRules.required]"
                          v-model="currResource.name"
                          autocomplete="off"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>

                    <!-- URL -->
                    <v-col cols="12" >
                      <div class="d-flex justify-content-between mt-n5">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >URL*</label
                        >
                      </div>
                      <div>
                        <v-text-field
                          type="input"
                          name="url"
                          :rules="[myRules.required]"
                          v-model="currResource.url"
                          autocomplete="off"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>

                    <!-- target -->
                    <v-col cols="12" >
                      <div class="d-flex justify-content-between mt-n5">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >target*</label
                        >
                      </div>
                      <div>
                        <v-text-field
                          type="input"
                          name="target"
                          :rules="[myRules.selected]"
                          v-model="currResource.target"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>

                    <!-- 排序 -->
                    <v-col cols="12" >
                      <div class="d-flex justify-content-between mt-n5">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >排序*</label
                        >
                      </div>
                      <div>
                        <v-text-field
                          type="input"
                          name="orderNumber"
                          :rules="[myRules.num]"
                          v-model="currResource.orderNumber"
                          autocomplete="off"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>

                  </v-row>
                </v-container>
                <span
                  class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                  id="kt_login_forgot"
                  >带 * 号的是必填项</span
                >
              </v-card-text>

              <!-- start:按钮区 -->
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="ma-2" color="success" type="submit" @click.prevent="resource2save()"> 保存 </v-btn>

                <v-btn
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="resourceDialog.dialog = false"
                >
                  关闭
                </v-btn>
              </v-card-actions>
              <!-- end:按钮区 -->
            </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </v-app>

    <!-- end:新增用户 -->
  </div>
</template>

<style lang="scss">
.my-1 {
  padding-right: 10px;
}
</style>

<script>

import Swal from "sweetalert2";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import Resource from "@/assets/js/pages/account/resource.js";

export default {
  name: "resource-list",

  data() {
    return {
      myRules: {
        selected: v => !!v || '必选',
        required: v => !!v || '不能为空',
        equalPwd: v => (v == this.currAccount.password) || '两次密码输入不一致',
        len_6_20: v => (v.length >= 6 && v.length <= 20) || "长度至少6位，最多20位",
        num: v => /^\d*$/.test(v) || "必须是数字"

      },
      currResource: {
        id: "",
        name: "",
        url: "",
        active: true,
        orderNumber: "",
        target: "self",
        bulidType: 1,
        categoryId: ""
      },
      search: {
        pageNum: 1,
        name: "",
        categoryId: null,
        url: ""
      },
      page: {
        rows: 100,
        pageSize: 5,
        resourceList: [],
      },
      resourceDialog: {
        title: "新增菜单",
        dialog: false,
        isAdd: false,
        categoryList: []
      },
      vselect: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 200
      }
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  components: {},
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "菜单管理", route: "" },
      { title: "菜单列表" }
    ]);
    this.resource2page();
    this.getCategoryList();
  },
  methods: {
    getCategoryList: function () {
      var that = this;
      Resource.getCategoryList().then(({ data }) => {
        that.resourceDialog.categoryList = data;
      });
    },

    resource2page: function () {
      var that = this;

      Resource.list(this.search).then(({ data }) => {
        that.page.resourceList = data.list;
        that.page.pageSize = data.pageSize;
        that.page.rows = data.total;

        that.search.pageNum = data.pageNum;
      });
    },

    resetResource: function () {
      this.currResource.id = "";
      this.currResource.name = "";
      this.currResource.url = "";
      this.currResource.active = true;
      this.currResource.orderNumber = "";
      this.currResource.target = "self";
      this.currResource.bulidType = 1;
      this.currResource.categoryId = "";
    },

    resource2save: function () {
      if(!this.$refs.form.validate()){
            return false;
      }
      var that = this;
      Resource.save(that.currResource).then(({ msg }) => {
        // 关闭模式窗口
        that.resourceDialog.dialog = false;
        // 重新加载页面列表
        that.resource2page();
        // 消息弹框
        window.Toast("success", msg);
        // 重置数据
        that.resetResource();
      });
    },

    resource2initAdd: function () {
      var that = this;
      that.resetResource();

      that.resourceDialog.dialog = true;
      that.resourceDialog.title = "新增菜单";
      that.resourceDialog.isAdd = true;

      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
    },

    resource2initEdit: function (id) {
      var that = this;

      Resource.findById(id).then(({ data }) => {
        // console.log(data);
        that.currResource = data;

        that.resourceDialog.dialog = true;
        that.resourceDialog.title = "修改菜单";
        that.resourceDialog.isAdd = false;

        if (this.$refs.form && this.$refs.form.resetValidation) {
          this.$refs.form.resetValidation();
        }
      });
    },

    resource2del: function (id) {
      Swal.fire({
        title:
          '<span style="color: black;">删除操作不可恢复，是否确定删除？<span>',
        showCancelButton: true,
        confirmButtonText: `确定`,
        cancelButtonText: `取消`
      }).then(result => {
        if (result.isConfirmed) {
          Resource.del(id).then(({ msg }) => {
            this.resource2page();
            window.Toast("success", msg);
          });
        }
      });
    }
  }
};
</script>