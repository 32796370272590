import ApiService from "@/core/services/api.service";

var Resource = {
    list: function(searchData) {
        return ApiService.post("/resource/list", searchData);
    },
    save: function(currAccount) {
        return ApiService.post("/resource/save", currAccount);
    },
    del: function(id) {
        return ApiService.get("/resource/del?id=" + id);
    },
    findById: function(id) {
        return ApiService.get("/resource/findById?id=" + id);
    },
    getCategoryList: function() {
        return ApiService.get("/resource/list-category");
    }
}

export default Resource;